<template>
	<li :class="'_pos_'+index">
		<router-link
			:to="'/episode/'+episode"
			title="view contents"
		>
			<span class="info_week">{{weekStr}}</span>
			<span class="info_thumb"><img :src="'https://img.youtube.com/vi/'+youtubeCode+'/hqdefault.jpg'" :alt="title+' 이미지'" /></span>
			<span class="info_subject">
				<span class="info_ep">{{episode}}</span>.
				<span class="info_title">{{title}}</span>
			</span>
			<span class="info_book" v-html="bibleStr" />
		</router-link>
	</li>
</template>

<script>


export default {
    name: 'ProductList',
	components: {
	},
    data: () => ({
		items:[]
    }),
	props:{
		index:{type: Number},
		episode:{type: Number},
		dateYear:{type: Number},
		dateMonth:{type: Number},
		dateWeek:{type: Number},
		bookCode:{type: Number},
		chapter:{type: Number},
		verseStart:{type: Number},
		verseEnd:{type: Number},
		youtubeCode:{type: String, default: ''},
		title:{type: String, default: ''}
	},
    computed: {
        lang() {
            return this.$store.state.lang;
        },
		weekStr(){
			return this.$store.state.weekForm(this.dateYear, this.dateMonth, this.dateWeek);
		},
		bibleStr(){
			return this.$store.state.bibleForm(this.bookCode, this.chapter, this.verseStart, this.verseEnd);
		}
    },
	methods: {
		viewItem(e, itemID, ){
			e.preventDefault();
			this.$emit('select-item', itemID);
		}
	},
	mounted(){
	}
}
</script>
