<template>
	<section class="_list">
		<h2 class="section_title">
			<span class="main_title">{{mainTitle}}</span>
			<span class="sub_title">{{subTitle}}</span>
		</h2>
		<div class="sec_wrap" id="listArea">
			<ul id="episodeList" class="con_list">
				<episode-item 
					v-for="(item, index) in items" :key="index" 
						:index="index"
						:episode="item.episode"
						:dateYear="item.dateYear"
						:dateMonth="item.dateMonth"
						:dateWeek="item.dateWeek"
						:bookCode="item.bookCode"
						:chapter="item.chapter"
						:verseStart="item.verseStart"
						:verseEnd="item.verseEnd"
						:youtubeCode="item.youtubeCode"
						:title="item.title"
						@select-item="selectListItem"
				/>
			</ul>
			<nav id="pagingList" class="paging_list">
				<a 
					v-for="(pageItem, index) in pageList" :key="index" 
					:href="pageItem.link" 
					title="link to list" 
					v-on:click="changePage($event, pageItem.link)"
					:class="'paging '+pageItem.css">{{pageItem.code}}</a>
			</nav>
			<div class="search_area" id="searchArea"></div>
		</div>
	</section>
</template>

<script>
import EpisodeItem from '@/components/EpisodeItem';
import axios from 'axios'
import langCommon from '@/language/common'
import buildMeta from '@/plugins/buildMeta'



export default {
    name: 'EpisodeList',
	components: {
		EpisodeItem
	},
    data: () => ({
		items:[],
		pageList:[]
    }),
	props:{
		page:{type: Number, default: 0},
		searchType:{type: String, default: ''},
		searchKeyword:{type: String, default: ''},
	},
    computed: {
		lang(){
			return this.$store.state.lang;
		},
		mainTitle(){
			return this.$store.state.headerTitle("episode", "main");
		},
		subTitle(){
			return this.$store.state.headerTitle("episode", "sub");
		}
    },
	methods: {
		loadData() {
			let vm= this, 
				data= {
					page:vm.page,
					searchType:vm.searchType,
					searchKeyword:vm.searchKeyword,
					lang:vm.lang
				}, 
				path=vm.$store.state.API_PATH+'episode';
				if (process.env.NODE_ENV == "development") {
					path+=".json";
				}
			axios.get(
                    path, 
                    {params: data}
                )
                .then((res) =>{
					let data = res.data;
					// console.log("then", data, data.result);
                    if (!data.result) return;
                    vm.items = data.data;
					vm.pageList = vm.buildPageList(data.pagination)
                    // setTimeout(() =>{
                    //     vm.updatePage();
                    // }, 1)
                })
                .catch((error)=>{
                    console.warn(error);
                })
		},
		buildPageList(pageData) {
			var pageGroupEa=5,
				num=pageData.pageTotal,
				pagingS = Math.floor((pageData.page) / pageGroupEa)*pageGroupEa, 
				pagingE = Math.min(num, pagingS+pageGroupEa),
				pageList = [],
				curr;
			pageList.push({code:'|<', link:'0', css:'_first'+(pagingS==0?' _dis':'')});
			pageList.push({code:'<', link:String(Math.max(pagingS-pageGroupEa, 0)), css:'_before'+(pagingS==0?' _dis':'')});
			for (var i=pagingS; i<pagingE; i++) {
				curr = i==pageData.page;
				pageList.push({code:String(i+1), link:(curr?'':''+i), css:'_page'+(curr?' _curr':'')});
			}
			pageList.push({code:'>', link:String(Math.min(pagingS+pageGroupEa, num-1)), css:'_after'+(pagingS+pageGroupEa>num?' _dis':'')});
			pageList.push({code:'>|', link:''+num, css:'_last'+(pagingS+pageGroupEa>num-1?' _dis':'')});
			return pageList;
		},
		changePage(e, pageNo) {
			e.preventDefault();
			let vm = this;
			if (pageNo) {
				vm.page = pageNo;
				vm.loadData();
			}
		},
		selectListItem(itemID) {
			this.$emit('select-list-item', itemID);
		}
	},
	mounted(){
		let vm = this;
		vm.loadData();
	},
	metaInfo(){
		if (this.$route.path != "/episode") return null;
		let lang = this.$store.state.lang;
		return buildMeta({
			target: this,
			isoCode: langCommon.isoCode[lang],
			title: langCommon.hibible[lang] + "-" + langCommon.nav.episode[lang],
			desc: '하이 바이블(HI BIBLE)은 기독교교육 전공자들이 살림교회를 통해서 만드는 어린이 성경 읽기 컨텐츠입니다. 하이 바이블 영상은 어린이 주일예배를 드리기 어려운 상황에 있는 교회와 어린이들을 위해 준비하였으며, 매주 금요일 오전에 업데이트됩니다. 하이 바이블은 어린이가 있는 가정들의 주중 가정예배에 사용 할 수 있도록 제작되었으며, 여러 나라 선교지에 있는 선교사님들의 어린이 교육을 위한 컨텐츠로 한국어외 다른 버전으로 제공됩니다. 하이 바이블과 함께 하나님 말씀을 배우는 즐거움이 우리 어린이들에게 있기를 소망합니다.',
			keyword: '하이바이블, hibible, 성경이야기',
			thumb: '/hibible/resource/images/main_image1.svg',
			link: "/hibible"+this.$route.path,
		});
	},
}
</script>
