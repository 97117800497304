<template>
	<section class="_view">
		<h2 class="section_title">
			<span class="main_title">{{item.title}}</span>
			<span class="sub_title">ep.{{item.episode}}</span>
		</h2>
		<div class="sec_wrap" id="viewArea">
			<div class="sec_wrap" id="viewArea">
				<div class="part_contents">
					<nav class="board_nav">
						<router-link
							to="/episode" 
							class="_back" 
							title="View episode movie"
						>
							{{backListStr}}
						</router-link>
						<a 
							href="video" 
							v-on:click="changeBoard($event, 'video')" 
							:class="'_board _video'+(boardCurr=='video'?' _curr':'')" 
							data-board-no="0" 
							title="Watch this week's episode video"
						>
							{{viewMovieStr}}
						</a>
						<a 
							href="summary" 
							v-on:click="changeBoard($event, 'summary')" 
							:class="'_board _summary'+(boardCurr=='summary'?' _curr':'')" 
							data-board-no="1" 
							title="View this week's summary"
						>
							{{summaryStr}}

						</a>
						<a 
							href="subject" 
							v-on:click="changeBoard($event, 'subject')" 
							:class="'_board _subject'+(boardCurr=='subject'?' _curr':'')" 
							data-board-no="2" 
							title="View this month's subject"
						>
							{{subjectStr}}
						</a>
					</nav>

					
					<swiper 
						class="con_board" 
						:options="swiperOption"
						ref="boardSwiper"
						@slideChange="onSlideChange"
					>
						<!-- <div class="board_wrap swiper-wrapper"> -->
						<swiper-slide class="art_board swiper-slide" id="video">
							<div class="art_wrap" :style="thumbnailStyle">
								<h5 class="mv_title">
									<span class="summary_episode">{{item.episode}}</span>.
									<span class="summary_title">{{item.title}}</span>
								</h5>
								<button class="btn_movie" id="watchEpisode" type="button" v-on:click="viewMovie($event)"  :data-youtube="item.youtubeCode">play</button>
							</div>
						</swiper-slide>
						<swiper-slide class="art_board swiper-slide" id="summary">
							<div class="art_wrap">
								<article class="board_article">
									<h5>
										<span class="summary_episode">{{item.episode}}.</span>
										<span class="summary_title">{{item.title}}</span>
										<span class="summary_book" v-html="bibleForm" />
									</h5>
									<pre v-html="weekContents"></pre> 
									<div class="data_area">
										<span class="data_item"
											v-for="(file, index) in files" :key="index" 
										>
											<span class="data_type">{{fileTypeName(file.fileType)}}</span>
											<span class="data_link">
												<a 
													:href="file.fileS3Link" 
													v-on:click="preview($event, file.fileS3Link, item.EpisodeId+'.'+item.title+' - '+fileTypeName(file.fileType))" 
													:data-preview="file.fileS3Link" 
													:data-preview-title="item.Episode+'.'+item.title+' - '+fileTypeName(file.fileType)" 
													class="data_link data_view">{{viewFileStr}}</a>
												<a 
													:href="file.fileS3Link"
													class="data_link data_download" 
													download>{{downloadStr}}</a>
											</span>
										</span>
									</div>
								</article>
							</div>
						</swiper-slide>
						<swiper-slide class="art_board swiper-slide" id="subject">
							<div class="art_wrap">
								<article class="board_article">
									<h5>
										<span class="subject_month">{{subjectMonthIndex}}</span>:
										<span class="subject_title">{{item.monthSubjectTitle}}</span>
									</h5>
									<pre>{{item.monthSubjectContent}}</pre>
									<div v-if="item.week1 || item.week2 || item.week3 || item.week4 || item.week5" class="week_list">
										<div v-if="item.week1" class="week_summary">
											<h6>{{weekForm(1)}}</h6>
											<pre v-html="weekContents1"></pre>
										</div>
										<div v-if="item.week2" class="week_summary">
											<h6>{{weekForm(2)}}</h6>
											<pre v-html="weekContents2"></pre>
										</div>
										<div v-if="item.week3" class="week_summary">
											<h6>{{weekForm(3)}}</h6>
											<pre v-html="weekContents3"></pre>
										</div>
										<div v-if="item.week4" class="week_summary">
											<h6>{{weekForm(4)}}</h6>
											<pre v-html="weekContents4"></pre>
										</div>
										<div v-if="item.week5" class="week_summary">
											<h6>{{weekForm(5)}}</h6>
											<pre v-html="weekContents5"></pre>
										</div>
									</div>
								</article>
							</div>  
						</swiper-slide>
						<!-- </div> -->
					</swiper>
						<div class="swiper-button-next"></div>
						<div class="swiper-button-prev"></div>
					<div class="board_info">
						<p>
							<span class="info_week" v-html="weekStr" />
							<span class="info_book" v-html="bibleForm" />
						</p>
					</div>
					<div class="board_accs">
						<img :src="$store.state.BASE_URL+'/resource/images/ep_board_acc_b.svg'" class="_acc_b"/>
						<img :src="$store.state.BASE_URL+'/resource/images/ep_board_acc_l.svg'" class="_acc_l"/>
						<img :src="$store.state.BASE_URL+'resource/images/ep_board_acc_r.svg'" class="_acc_r"/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import languagePack from '@/language/episode'
import langCommon from '@/language/common'
import buildMeta from '@/plugins/buildMeta'


export default {
    name: 'EpisodeView',
	components: {
		swiper,
		swiperSlide
	},
    data: () => {
		return {
			itemID:'',
			item:{
				EpisodeId:"",
				langId:"",
				bookCode:"",
				chapter:"",
				dateMonth:"",
				dateWeek:"",
				dateYear:"",
				lang:"",
				monthSubjectContent:"",
				monthSubjectTitle:"",
				summaryContent:"",
				title:"",
				verseEnd:"",
				verseStart:"",
				youtubeCode:""
			},
			files:[
				// {
				// 	episode: 254,
				// 	fileId: 1,
				// 	fileS3Link: "https://hibible.s3.ap-northeast-2.amazonaws.com/act/2021/sample_act.pdf",
				// 	fileName: "sample_act.pdf",
				// 	fileType: "act"
				// }
			],
			swiperOption:{
				autoHeight: true,
				spaceBetween: 20,
				allowTouchMove: false,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev",
				},
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				}
			},
			boardCurr:"video",
			viewMvRemBtn:null,
		}
    },
	props:{
	},
    computed: {
		lang(){
			return this.$store.state.lang;
		},
        bibleForm() {
			let vm=this, item=vm.item;
			return vm.$store.state.bibleForm(item.bookCode, item.chapter, item.verseStart, item.verseEnd);
        },
        bibleStr() {
			let vm=this, item=vm.item;
			return vm.$store.state.bibleStr(item.bookCode, item.chapter, item.verseStart, item.verseEnd);
        },
		weekStr(){
			let vm=this, item=vm.item;
			return this.$store.state.weekForm(item.dateYear, item.dateMonth, item.dateWeek);
		},

		thumbnailStyle(){
			let vm=this, item=vm.item;
			if (item.youtubeCode) return 'background-image:url(https://img.youtube.com/vi/'+item.youtubeCode+'/maxresdefault.jpg);';
			else return '';
		},
		backListStr() {
			return languagePack.backList();
		},
		viewMovieStr() {
			return languagePack.viewMovie();
		},
		summaryStr() {
			return languagePack.summary();
		},
		subjectStr() {
			return languagePack.subject();
		},
		viewFileStr() {
			return langCommon.filePreview();
		},
		downloadStr() {
			return langCommon.fileDownload();
		},
		subjectMonthIndex() {
			return languagePack.subjectTitle(this.item.dateMonth);
		},
		weekContents() {
			if (!this.item.dateWeek) return '';
			else return this.makePreTitle(this.item['week'+this.item.dateWeek]);
		},
		weekContents1() {
			if (this.item.week1) return this.makePreTitle(this.item.week1);
			else return '';
		},
		weekContents2() {
			if (this.item.week2) return this.makePreTitle(this.item.week2);
			else return '';
		},
		weekContents3() {
			if (this.item.week3) return this.makePreTitle(this.item.week3);
			else return '';
		},
		weekContents4() {
			if (this.item.week4) return this.makePreTitle(this.item.week4);
			else return '';
		},
		weekContents5() {
			if (this.item.week5) return this.makePreTitle(this.item.week5);
			else return '';
		}
    },
	setup() {
		const onSwiper = (swiper) => {
		swiper;
      };
      const onSlideChange = () => {
        // console.log('slide change');
      };
      return {
        onSwiper,
        onSlideChange,
      };
	},
	methods: {
		changeContent(itemID){
			this.itemID = String(itemID);
			this.loadData();
			this.loadFile();
		},
		loadData() {
			let vm= this, 
				data= {
					lang:vm.lang
				}, 
				path=vm.$store.state.API_PATH+'episode/'+this.itemID;
				if (process.env.NODE_ENV == "development") path+=".json";
			axios.get(
                    path, 
                    {params: data}
                )
                .then((res) =>{
                    let data = res.data;
                    if (!data.result) return;
                    vm.item = data.data;
					vm.$store.commit("setPageType", {name:"episode", type:"view"});
                })
                .catch((error)=>{
                    console.warn(error);
                }
			)
		},
		makePreTitle(str) {
			str = str.split("[[").join('<h4 class="pre_title">').split("]]").join('</h4>');
			str = str.split("{{").join('<h5 class="pre_title">').split("}}").join('</h5>');
			str = str.split("((").join('<h6 class="pre_title">').split("))").join('</h6>');
			// str = str.split("</h6>   ").join('</h6>').split("</h6>  ").join('</h6>').split("</h6> ").join('</h6>');
			// str = str.split("</h6>\n").join('</h6>');
			return str;
		},
		loadFile() {
			let vm= this, 
				data= {
					lang:vm.lang
				}, 
				path=vm.$store.state.API_PATH+'episode/'+this.itemID+"/files";
				if (process.env.NODE_ENV == "development") path+=".json";
			axios.get(
                    path, 
                    {params: data}
                )
                .then((res) =>{
                    let data = res.data;
                    if (!data.result) return;
                    vm.files = data.data;
                })
                .catch((error)=>{
                    console.warn(error);
                }
			)
		},
		fileTypeName(fileType){
			var tp = this.$store.state.fileTypeName(fileType);
			return tp;
		},
		viewList(e) {
			e.preventDefault();
			this.$emit('close-view', "");
		},
		onSlideChange() {
			var swiper=this.$refs.boardSwiper.swiper, _no = swiper.activeIndex;
			if (_no == 1) this.boardCurr = "summary";
			else if (_no == 2) this.boardCurr = "subject";
			else this.boardCurr = "video";
		},
		changeBoard(e, board) {
			e.preventDefault();
			var _no, swiper=this.$refs.boardSwiper.swiper;
			this.boardCurr = board;
			if (board == "summary") {
				_no = 1;
			} else if (board == "subject") {
				_no = 2;
			} else {
				_no = 0;
			}
			swiper.slideTo(_no);
			// console.log(this.swiper, this, );
			// this.swiper.slideTo(_no);
		},
		preview(e, url, title) {
			e.preventDefault();
			this.$store.state.openPreview(url, title);
		},
		weekForm(ww) {
			return langCommon.weekForm(ww);
		},
		viewMovie(e){
			let vm = this;
			e.preventDefault();

			var yid = vm.item.youtubeCode, url='https://www.youtube.com/embed/'+yid+'?autoplay=1&fs=0&rel=0&loop=0&modestbranding=1', str='<div id="myvideo"><iframe id="mvPlayer" width="100%" height="100%" src="'+url+'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe><button type="button" id="closeMovie">X CLOSE</button></div>';
			if (document.querySelector("#myvideo")) {
				document.body.removeChild(document.querySelector("#myvideo"));
			}
			if (vm.$store.state.isMobile) {
				window.open(url);
				return;
			}
			document.querySelector('body').insertAdjacentHTML('beforeend', str);
			var elem = document.getElementById("myvideo");
			openFullscreen();
			setTimeout(function(){
				elem.querySelector("#closeMovie").classList.add("_timer");
			}, 10);
			elem.querySelector("#closeMovie").addEventListener("click", clooseFullscreen);
			elem.querySelector("iframe#mvPlayer").addEventListener("touchstart", ()=>{  });
			function openFullscreen() {
				if (elem.requestFullscreen) {
					elem.requestFullscreen();
				} else if (elem.webkitRequestFullscreen) { /* Safari */
					elem.webkitRequestFullscreen();
				} else if (elem.msRequestFullscreen) { /* IE11 */
					elem.msRequestFullscreen();
				} else if (elem.mozRequestFullScreen) { /* MOZ */
					elem.mozRequestFullScreen();
				}
			}
			function clooseFullscreen() {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.webkitExitFullscreen) {
					document.webkitExitFullscreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				}
				document.body.removeChild(document.querySelector("#myvideo"));
			}
			vm.viewMvRemBtn = function() {
				if (document.querySelectorAll("#closeMovie").length) {
					var btn = document.querySelector("#closeMovie");
					btn.classList.remove("_timer");
					setTimeout(function(){
						btn.classList.add("_timer");
					}, 10);
				}
				
			}
		}
	},
	mounted(){
		let vm = this;
		window.addEventListener("mousemove", function () {
			if (vm.viewMvRemBtn) vm.viewMvRemBtn();
		});
	},
	metaInfo(){
		if (this.$route.path == "/episode") return null;
		let lang = this.$store.state.lang;
		return buildMeta({
			target: this,
			isoCode: langCommon.isoCode[lang],
			title: this.item.EpisodeId+'.'+this.item.title,
			desc: this.bibleStr+" "+String(this.item['week'+this.item.dateWeek]).split("\n").join(""),
			keyword: '하이바이블, hibible, 성경이야기',
			thumb: 'https://img.youtube.com/vi/'+this.item.youtubeCode+'/maxresdefault.jpg',
			link: '/hibible'+this.$route.path,
		});
	},
}
</script>
