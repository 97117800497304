const engMonth = (mm)=> {
	mm = String(mm);
	if (mm == "1") return "January";
	else if (mm == "2") return "February";
	else if (mm == "3") return "March";
	else if (mm == "4") return "April";
	else if (mm == "5") return "May";
	else if (mm == "6") return "June";
	else if (mm == "7") return"July";
	else if (mm == "8") return "August";
	else if (mm == "9") return "September";
	else if (mm == "10") return "October";
	else if (mm == "11") return "November";
	else if (mm == "12") return "December";
};
const engWeek = (ww)=> {
	ww = String(ww);
	if (ww == "1") return "1st";
	else if (ww == "2") return "2nd";
	else if (ww == "3") return "3rd";
	else if (ww == "4") return "4th";
	else if (ww == "5") return "5th";
};
const lang = localStorage.getItem("lang");
const languagePack = {
	backList:function(){
		var data={
			kor:"리스트 돌아가기", eng:"Return to list", rus:"Обратно к списку", nep:"back", tai:"ก่อนหน้า", mog:"Жагсаалт руу буцах", bur:"မာတိကာသို့ ပြန်သွားရန်", kaz:"Return to list"
		};
		return data[lang];
	},
	viewMovie:function(){
		var data={
			kor:"영상 보기", eng:"View video", rus:"Смотреть видеоролики", nep:"भिडियो हेर", tai:"คลิปวิดีโอ", mog:"Бичлэг үзэх", bur:"ဗီဒီယို ကြည့်ရန်", kaz:"View video"
		};
		return data[lang];
	},
	summary:function(){
		var data={
			kor:"이번주 요약", eng:"summary", rus:"краткое содержание", nep:"सामग्री सारांश", tai:"สรุปเนื้อหา", mog:"агуулгын хураангуй", bur:"summary", kaz:"summary"
		};
		return data[lang];
	},
	subject:function(){
		var data={
			kor:"이달의 주제", eng:"Subject of the month", rus:"Тема текущего месяца", nep:"subject", tai:"สำนักงานวิทยทรัพยากร", mog:"Энэ сарын сэдэв", bur:"ယခုလ ခေါင်းစဉ်", kaz:"Oсы айдың тақырыбы"
		};
		return data[lang];
	},
	subjectTitle:function(mm){
		var data={
			kor:"하이바이블 MM월의 말씀", eng:"HiBible lesson for MM", rus:"Привет Библия слово MM месяца", nep:"HiBible lesson for MM", tai:"พระธรรม MM", mog:"Сайн уу Библи MM сарын үг", bur:"HiBible၏ MMလ အမှာစကား", kaz:"HiBible MM-нші айдың сөз"
		};
		if (lang == "eng") return data[lang].split("MM").join(engMonth(mm));
		else return data[lang].split("MM").join(mm);
	},
	bookForm:function(bb, ss, ee){
		var data={
			kor:"BB장 SS절 ~ EE절", eng:"Chapter BB, Verse SS-EE", rus:"BB глава SS стих ~ EE стих", nep:"Chapter BB, Verse SS-EE", tai:"Chapter BB, Verse SS-EE", mog:"BB бүлэг SS ишлэл ~ EE илшлэл", bur:"အခန်းBB အခန်းငယ်SS ~ အပိုဒ်EE", kaz:"BB тарау SS ~ EE аяттар"
		};
		return data[lang].split("BB").join(bb).split("SS").join(ss).split("EE").join(ee);
	},
	dateWeekForm:function(yyyy, mm, ww){
		var data={
			kor:"YYYY년 YY월 WW주", eng:"WW week of MM, YYYY", rus:"YYYYгод MMмесяц WWнеделя", nep:"WW week of MM, YYYY", tai:"อาทิตยที่WW เดือนMM ปีYYYY", mog:"YYYYон MMсар WWдолоо хоног", bur:" YYYYနှစ် MMလ WWအပတ်", kaz:"YYYY жыл MM - ай  WW - апта"
		};
		if (lang == "eng") return data[lang].split("YYYY").join(yyyy).split("MM").join(engMonth(mm)).split("WW").join(engWeek(ww));
		else return data[lang].split("YYYY").join(yyyy).split("MM").join(mm).split("WW").join(ww);
	},
}
export default languagePack;
