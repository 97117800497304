<template>
	<div :class="'main_wrap _ep _page_'+pageMode">
		<episode-list 
			@select-list-item="viewItem"
		/>
		<episode-view 
			@close-view="viewList"
			ref="epsodeView"
		/>
	</div>
</template>

<script>
import EpisodeList from '@/components/EpisodeList'
import EpisodeView from '@/components/EpisodeView'
// import langCommon from '@/language/common'

export default {
  	name: 'Episode',
	props: {
		// lang:{type: String, default: 'kor'}
	},
	data: () => ({
		pageMode:"list",
		itemID:""
	}),
	components:{
		EpisodeList, EpisodeView
	},
    computed: {
        contentNo(){
			console.log("change no", this.$route.params.contentNo);
            return this.$route.params.contentNo;
        },

    },
	methods: {
		viewList(){
			var vm = this;
			vm.pageMode = "list"
			vm.itemID = "";
			vm.$store.commit("setPageType", {name:"episode", type:"list"});
		},
		viewItem(itemID){
			var vm = this;
			vm.pageMode = "view"
			vm.itemID = String(itemID);
			vm.$refs.epsodeView.changeContent(itemID);
		},
		changeEpisode() {
			var vm = this;
			if (vm.contentNo) vm.viewItem(vm.contentNo);
			else vm.viewList();
		}
	},
	watch: {
		'$route' () {
			console.log("change episode");
			this.changeEpisode();
		}
	},
	mounted(){
		this.changeEpisode();
		// console.log("contentNo", this.contentNo);
		// setTimeout(()=>{
		// 	this.viewList();
		// }, 100);
	},
	created(){
		// let vm = this;
		// vm.lang = vm.$store.state.lang;
	}
}
</script>